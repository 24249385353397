import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of deload week, test week starts tomorrow!`}</em></p>
    <p>{`DB Push Press 12-12-12`}</p>
    <p>{`DB Bent Side Laterals 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-DB S2OH (45/30)`}</p>
    <p>{`10-Box Jumps (24/20)`}</p>
    <p>{`10-Back Extensions`}</p>
    <p>{`10-Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday will be our monthly free class so bring a friend!
 8:00 & 9:00am at The Ville and 10:00 & 11:00am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      